
import React from "react";
export default function Theme(props) { 
  return (<div>
      <div
        className="v-admin-login"
        style={{background: 'url(https://cdn.vostro.cloud/hotspots/scc/scc-bg.jpg) 0% 0% / cover rgb(41, 106, 202)'}}
      >
        <div className="container-fluid fh" style={{ minHeight: "100vh" }}>
          <div className="row header row align-items-center">
            <div className="col">
              <div className="container">
                <div className="row">
                  <div className="col-auto mx-auto mx-lg-0">
                    <a
                      className="header__logo__test"
                      href="https://www.sunshinecoast.qld.gov.au/"
                      title="Home – SCC Main Website. Logo."
                    >
                      <span className="sr-only"></span>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row align-items-center fh-element">
            <div className="col">
              <div className="container">
                <div className="row align-items-center">
                  <div className="col-6 d-none d-lg-block">
                    <div className="headers">
                      <div className="heading-1">{"Welcome to"}</div>
                      <div className="heading-2">{"Sunshine Coast"}</div>
                      <div className="heading-3">{"Free Wi-Fi"}</div>
                    </div>
                  </div>
                  <div className="col-10 d-block d-lg-none mx-auto">
                    <div className="headers text-center my-3">
                      {"Welcome to Sunshine Coast"}<br/><br/>
                      {"Free Wi-Fi"}
                    </div>
                  </div>
                  <div className="login-control mx-auto ml-lg-auto col-12 col-lg-5 col-xl-6">
                    <div className="paper shaddow paper-padding">
                      <div className="container-fluid no-gutters ">
                        <div className="row">
                          <div className="col">{props.children}</div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row fatfooter align-items-center">
            <div className="col">
              <div className="container">
                <div className="row">
                  <div className="col-auto">
                    <i className="fa fa-copyright mr-1"></i>
                    {`Sunshine Coast Regional Council ${new Date().getFullYear()}`}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>);
}
